// ** Reducers Imports 
import layout from "./layout"
import navbar from "./navbar"
import auth from './slices/auth.Slice'
import users from './slices/usersSlice'
import product from './slices/productSlice'
import countries from './slices/countrySlice'


const rootReducer = {
    navbar,
    layout,
    auth,
    users,
    product,
    countries,
}

export default rootReducer
