import { createSlice } from "@reduxjs/toolkit"

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countryList: [],
    totalEntry: 0,
    filterTerm: '' 
  }, 
  reducers: {
    setCountryList: (state, action) => {
      state.countryList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    clearCountry: () => initialState

  }
})

export const {
  setCountryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList,
  clearCountry
} = countrySlice.actions

export const selectCountryList = (state) => state.countries.countryList
export const selectFilterTerm = (state) => state.countries.filterTerm
export const selectTotalEntry = (state) => state.countries.totalEntry

export default countrySlice.reducer
